import React, { ReactElement } from "react";
import { getPageProps } from "@services/initial-calls";
import { GetServerSideProps } from "next";
import Wrapper from "@page-builder/wrapper";
import Page from "@page-builder/page";

export default function Index(): ReactElement {
    return (
        <Wrapper>
            <Page />
        </Wrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "page-builder");
};
