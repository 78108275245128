import React, { CSSProperties, ReactElement } from "react";
import styles from "./styles.module.scss";
import { Variables } from "@page-builder/ts/types";
import {
    convertToBreakpointVariables,
    modifyCSSForElement,
} from "@page-builder/utils";
import dynamic from "next/dynamic";
import Head from "next/head";

import { Component } from "@page-builder/ts/generated";
const Form = dynamic(() => import("../components/form"));
const FeatureList = dynamic(() => import("../components/featureList"));
const AffiliateOfferList = dynamic(
    () => import("../components/affiliateOfferList"),
);
const TextBlock = dynamic(() => import("@page-builder/components/textBlock"));
const TermsAndConditions = dynamic(
    () => import("@page-builder/components/termsAndConditions"),
);
const Blog = dynamic(() => import("@page-builder/components/blog"));
const ContactUs = dynamic(() => import("@page-builder/components/contactUs"));
const ScrollToTop = dynamic(
    () => import("@page-builder/components/scrollToTop"),
);
const ContentWithImage = dynamic(
    () => import("@page-builder/components/contentWithImage"),
);
const Claims = dynamic(() => import("@page-builder/components/Claims"));
const Faq = dynamic(() => import("@page-builder/components/faq"));
const FeatureListWithImage = dynamic(
    () => import("@page-builder/components/featureListWithImage"),
);
const StandAloneImage = dynamic(
    () => import("@page-builder/components/standAloneImage"),
);
const LookupsList = dynamic(
    () => import("@page-builder/components/lookupsList"),
);
const PrivacyPolicy = dynamic(
    () => import("@page-builder/components/privacyPolicy"),
);
const CategoryList = dynamic(
    () => import("@page-builder/components/categoryList"),
);
const ThankYou = dynamic(() => import("@page-builder/components/thankYou"));
const Button = dynamic(() => import("@page-builder/components/button"));

const availableComponents = {
    Form,
    FeatureList,
    AffiliateOfferList,
    TextBlock,
    PrivacyPolicy,
    TermsAndConditions,
    Blog,
    ContactUs,
    ScrollToTop,
    ContentWithImage,
    Claims,
    Faq,
    FeatureListWithImage,
    StandAloneImage,
    LookupsList,
    CategoryList,
    ThankYou,
    Button,
};

export default function Section({
    variables = {},
    components,
    sectionIndex,
    classIdentifier,
}: {
    variables?: Variables;
    components: Component[];
    sectionIndex: number;
    classIdentifier?: string;
}): ReactElement {
    return (
        <section
            style={
                {
                    ...convertToBreakpointVariables(variables),
                    "--formBackground-sm": components.some(
                        (i) => i.id === "Form" || i.id === "Claims",
                    )
                        ? "var(--global-form-general-containing-section-background-color)"
                        : undefined,
                } as CSSProperties
            }
            className={`${styles.section} ${
                classIdentifier ? classIdentifier : ""
            }`}
        >
            {components.map((component, componentIndex) => {
                const ResolvedComponent = availableComponents[component.id];

                if (!ResolvedComponent) {
                    return null;
                }

                return (
                    <div
                        style={{
                            ...convertToBreakpointVariables({
                                ...component.variables,
                            }),
                        }}
                        key={componentIndex}
                        className={`${styles.component} ${
                            component.classIdentifier
                                ? component.classIdentifier
                                : ""
                        }`}
                        id={`${component.id}-${componentIndex}-${sectionIndex}`}
                    >
                        <ResolvedComponent props={component.props} />
                        {component.css ? (
                            <Head>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: modifyCSSForElement(
                                            component.css,
                                            `#${component.id}-${componentIndex}-${sectionIndex}`,
                                        ),
                                    }}
                                />
                            </Head>
                        ) : null}
                    </div>
                );
            })}
        </section>
    );
}
