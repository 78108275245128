import { useDomainContext } from "@hooks/useDomainContext";
import { ReactElement, useState } from "react";

import Section from "./section";
import Modal from "@components/shared/modal";
import CloseIcon from "@components/svg/closeIcon";

export default function Page(): ReactElement {
    const { routeConfig, category } = useDomainContext();
    const [showModal, setShowPartnerPopup] = useState(false);

    if (!routeConfig || !routeConfig.config?.sections) {
        return <></>;
    }

    return (
        <div className="flex flex-col">
            {routeConfig.config?.sections.map((section, index: number) => {
                return (
                    <Section
                        key={index}
                        variables={section.variables ?? {}}
                        components={section.components}
                        sectionIndex={index}
                        classIdentifier={section.classIdentifier}
                    />
                );
            })}
            {category?.slug === "auto-accident" && (
                <section className="mb-[5rem]">
                    <div>
                        <p
                            id="legal-notice"
                            className="text-center text-xs text-gray-400"
                        >
                            Advertising is paid for by participating attorneys
                            in a joint advertising program, licensed to practice
                            law only in California. A complete list of joint
                            advertising attorneys can be found
                            <span className="text-[blue] cursor-pointer">
                                {" "}
                                <span
                                    id="modal-trigger"
                                    className="color:#3498db; text-[#3498db]"
                                    onClick={() => {
                                        setShowPartnerPopup(true);
                                    }}
                                >
                                    here
                                </span>
                            </span>
                            . You can request an attorney by name. We are not a
                            law firm or an attorney referral service. This
                            advertisement is not legal advice and is not a
                            guarantee or prediction of the outcome of your legal
                            matter. Every case is different. The outcome depends
                            on the laws, facts, and circumstances unique to each
                            case. Hiring an attorney is an important decision
                            that should not be based solely on advertising.
                            Request free information about your attorney's
                            background and experience. This advertising does not
                            imply a higher quality of legal services than that
                            provided by other attorneys. This advertising does
                            not imply that the attorneys are certified
                            specialists or experts in any area of law. No legal
                            services will be provided unless a signed agreement
                            between the client and the attorney exists. We use
                            cookies to personalize content and to analyze our
                            traffic. We also share information about your use of
                            our site with our analytics partners, who may
                            combine it with other information you've provided or
                            collected from your use of their services. You
                            consent to our cookies if you continue to use our
                            website.
                        </p>
                    </div>
                    <Modal
                        onClose={() => {
                            setShowPartnerPopup(false);
                        }}
                        active={showModal}
                        closeIcon={<CloseIcon />}
                        backdropClosable
                        modalStyles={{
                            display: "flex",
                            flexDirection: "column",
                            maxHeight: "100%",
                        }}
                        modalClassName="h-[90vh] rounded"
                        bodyStyles={{ overflowY: "scroll" }}
                        width={900}
                        header={"Sponsors"}
                    >
                        <div className="pb-5 lg:px-9 px-5">
                            <div className="flex flex-col">
                                <p>- Adam Birkhold</p>

                                <p>- Al Motlagh</p>

                                <p>- Alan D Daneshrad</p>

                                <p>- Ali A Azarakhsh</p>

                                <p>- Ali Awad</p>

                                <p>- Ali Razavi</p>

                                <p>- Alina Bagasian</p>

                                <p>- Alla Tenina</p>

                                <p>- Ameer Shah</p>

                                <p>- Andrew D Kumar</p>

                                <p>- Andrew Zeytuntsyan</p>

                                <p>- Anthony Choe</p>

                                <p>- Aram Rostomyan</p>

                                <p>- Aren Manukyan</p>

                                <p>- Ari Moss</p>

                                <p>- Arin Khodaverdian</p>

                                <p>- Aron C Movroydis</p>

                                <p>- Artin Sookasian</p>

                                <p>- Ashkan Minaie</p>

                                <p>- Ayesha Rafi</p>

                                <p>- Barry H Hinden</p>

                                <p>- Ben Dominguez II</p>

                                <p>- Benjamin Fogel</p>

                                <p>- Benjamin Khakshour</p>

                                <p>- Bita N Haiem</p>

                                <p>- Bobby B Saadian</p>

                                <p>- Bobby Tamari</p>

                                <p>- Brian Banner</p>

                                <p>- Brian C Mitchell</p>

                                <p>- Cagney McCormick</p>

                                <p>- Cameron Y Brock</p>

                                <p>- Christopher Bragoli</p>

                                <p>- Christopher Culleton</p>

                                <p>- Clifford J Enten</p>

                                <p>- D. Scott Warmuth</p>

                                <p>- Dan Abir</p>

                                <p>- Daniel A Reisman</p>

                                <p>- Daniel Bottari</p>

                                <p>- Daniel J Rafii</p>

                                <p>- Darren Miller</p>

                                <p>- David Benn</p>

                                <p>- David E Jacobson</p>

                                <p>- David F Makkabi</p>

                                <p>- David Krangle</p>

                                <p>- David Kreizer</p>

                                <p>- David L Issapour</p>

                                <p>- David P Bonemeyer</p>

                                <p>- David P Kashani</p>

                                <p>- David Yerushalmi</p>

                                <p>- Derek Lee</p>

                                <p>- Edward Herman</p>

                                <p>- Edward Okwueze</p>

                                <p>- Edward Ramsey</p>

                                <p>- Elliot Zarabi</p>

                                <p>- Eric Mausner</p>

                                <p>- Erik Zograbian</p>

                                <p>- Felicia B Edelman</p>

                                <p>- Fletcher B Brown</p>

                                <p>- Gary Berkovich</p>

                                <p>- Gary K Daglian</p>

                                <p>- Geoffrey P Norton</p>

                                <p>- George Jawlakian</p>

                                <p>- George P Escobedo</p>

                                <p>- George P Hakim</p>

                                <p>- George Salinas</p>

                                <p>- Gerry Hernandez</p>

                                <p>- Gil Alvandi</p>

                                <p>- Goldwater Partner</p>

                                <p>- Gordon McKernan</p>

                                <p>- Granth J Crhoelman</p>

                                <p>- Gus Anastopoulo</p>

                                <p>- Hagop Chopurian</p>

                                <p>- Harout A Messrelian</p>

                                <p>- Irina Martirosyan</p>

                                <p>- James A Allaire</p>

                                <p>- James Kim</p>

                                <p>- James Onder</p>

                                <p>- James Shaw</p>

                                <p>- James White</p>

                                <p>- Jared S Zafran</p>

                                <p>- Jared Spingarn</p>

                                <p>- Jason B Chalik</p>

                                <p>- Jason Javaheri</p>

                                <p>- Jeffrey Knoll</p>

                                <p>- Jerrold Parker</p>

                                <p>- Jerry Jacobson</p>

                                <p>- Jimmy H Jin</p>

                                <p>- John Brockmeier</p>

                                <p>- John C Ye</p>

                                <p>- John Hong</p>

                                <p>- John Leo</p>

                                <p>- Johnny G Phillips</p>

                                <p>- Jonathan I Rotstein</p>

                                <p>- Jonathan Melmed</p>

                                <p>- Jonathan Yagoubzadeh</p>

                                <p>- Joseph Nazarian</p>

                                <p>- Joseph S Nourmand</p>

                                <p>- Joshua J Zokaeem</p>

                                <p>- Justin Farahi</p>

                                <p>- Justin L Lawrence</p>

                                <p>- Kaveh Elihu</p>

                                <p>- Kenny Habetz</p>

                                <p>- Kevin A Garcia</p>

                                <p>- Kevin Butler</p>

                                <p>- Kevin Danesh</p>

                                <p>- Kevin Jani</p>

                                <p>- Kevin Moore</p>

                                <p>- Khalil Khan</p>

                                <p>- Kian Mottahedeh</p>

                                <p>- Kyle Madison</p>

                                <p>- Mahdis Kaeni</p>

                                <p>- Maralle Messrelian</p>

                                <p>- Marc Pacin</p>

                                <p>- Marielys Acosta</p>

                                <p>- Mark Sweet</p>

                                <p>- Martin Arteaga</p>

                                <p>- Matt Koohanim</p>

                                <p>- Matthew Buzzell</p>

                                <p>- Michael Avanesian</p>

                                <p>- Michael Emrani</p>

                                <p>- Michael Fielding</p>

                                <p>- Michael Ghozland</p>

                                <p>- Michael H Kim</p>

                                <p>- Michael Pierce</p>

                                <p>- Michael Saeedian</p>

                                <p>- Michael Steinger</p>

                                <p>- Miguel I Alvarez</p>

                                <p>- Mohammad (Mo) Abuershaid</p>

                                <p>- Nassir N Ebrahimian</p>

                                <p>- Nathaniel Preston</p>

                                <p>- Nilufar Alemozaffar</p>

                                <p>- Omid Razi</p>

                                <p>- Pavel Sterin</p>

                                <p>- Payam Tishbi</p>

                                <p>- Pouya Chami</p>

                                <p>- Ramin Kermani-Nejad</p>

                                <p>- Randal Klezmer</p>

                                <p>- Raphael B Hedwat</p>

                                <p>- Raymond Ghermezian</p>

                                <p>- Ricardo Y Merluza</p>

                                <p>- Rob A Rodriguez</p>

                                <p>- Robert M Pave</p>

                                <p>- Robin Saghian</p>

                                <p>- Robinson S Rowe</p>

                                <p>- Ronald DeSimone</p>

                                <p>- Ronen Kleinman</p>

                                <p>- Rouben Varozian</p>

                                <p>- Ryan Banafshe</p>

                                <p>- Sam Almasri</p>

                                <p>- Samuel Ceballos</p>

                                <p>- Sanam Salimnia Aghnami</p>

                                <p>- Scott Diallo</p>

                                <p>- Scott E Wheeler</p>

                                <p>- Sean Logue</p>

                                <p>- Sean Simpson</p>

                                <p>- Sef Krell</p>

                                <p>- Servando Timbol</p>

                                <p>- Seymone Javaherian</p>

                                <p>- Sharif Alkalbani</p>

                                <p>- Shawn Azizzadeh</p>

                                <p>- Shervin Lalezary</p>

                                <p>- Siamak Vaziri</p>

                                <p>- Stacy Kemp</p>

                                <p>- Stephan Airapetian</p>

                                <p>- Stephen Godwin</p>

                                <p>- Stephen Kwan</p>

                                <p>- Thomas A Cifarelli</p>

                                <p>- Thomas Combs</p>

                                <p>- Thomas G Kemerer</p>

                                <p>- Tigran Martinian</p>

                                <p>- Troy T Otus</p>

                                <p>- Vivian N Szawarc</p>

                                <p>- Yasmin Azimi</p>
                            </div>
                        </div>
                    </Modal>
                </section>
            )}
        </div>
    );
}
